import logo from './logo-wtrans.png';
import notfound from './notfound.png';
import './App.css';
import { useRef, useState, useEffect } from 'react';
import Web3 from "web3";
import ERC721 from "./ERC721.json";
import axios from 'axios';
import IPFSGatewayTools from "@pinata/ipfs-gateway-tools/dist/browser";
import { FaSearch, FaTimes } from 'react-icons/fa';
// import DonateButton from "./DonateButton";

const gatewayTools = new IPFSGatewayTools();

function App() {
  const addressRef = useRef();
  const collectionRef = useRef();
  const web3 = new Web3("https://rpc.pulsechain.com")
  const apiEndpoint = "https://nftsearch-pulsechain.herokuapp.com"
  const [retrieving, setRetrieving] = useState(false)
  const [collectionsFound, setCollectionsFound] = useState(null);
  const [error, setError] = useState(null);
  const [errorSearch, setErrorSearch] = useState(null);
  const [collections, setCollections] = useState(null);
  const [addresses, setAddresses] = useState(null);
  const [nftsFound, setNftsFound] = useState(0);
  const [searchAddress, setSearchAddress] = useState(null);

  // More Info Button
  const [activeCollection, setActiveCollection] = useState(null)

  const [isOpen, setIsOpen] = useState(false);
  const popupRef = useRef(null);

  const togglePopup = (e) => {
    setActiveCollection({
      contractAddress: e.target.getAttribute("address"),
      name: e.target.getAttribute("name"),
      image: e.target.getAttribute("image")
    })
    setIsOpen(!isOpen);
  };

  const closePopup = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        closePopup();
      }
    };

    if (isOpen) {
      document.addEventListener('mousedown', handleOutsideClick);
    }

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [isOpen]);

  const checkIfNftOnPulseChain = async (contract, token, address) => {
    const erc721 = new web3.eth.Contract(ERC721.abi, contract)

    try {
      let owner = await erc721.methods.ownerOf(token).call();
      if (owner.toLowerCase() === address.toLowerCase()) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }

  }

  const makeAPICall = async () => {

    let collections = {}
    const address = addressRef.current.value.toLowerCase();
    if (web3.utils.isAddress(address)) {
      setRetrieving(true)
      setError(null)
      setSearchAddress(null)
      setCollectionsFound(null)
      setAddresses(null)
      setCollections(null)
      setNftsFound(0)

      let userInfo;
      try {
        userInfo = await axios(`${apiEndpoint}/user-info?address=${address}`);
      } catch (error) {
        setRetrieving(false)
        if (error.response && error.response.status === 429) {
          setError(error.response.data.error)
        } else {
          setError("Internal Server Error. Please try again later.")
        }

        return;
      }

      const nfts = userInfo["data"]["nfts"]
      const transfers = userInfo["data"]["transfers"]

      for (let i = 0; i < nfts.length; i++) {
        const contractAddress = nfts[i].contract.address.toLowerCase();
        const tokenId = nfts[i].id.tokenId;
        const nftOwned = await checkIfNftOnPulseChain(contractAddress, tokenId, address)

        if (nfts[i].error) {
          continue;
        }

        if (nftOwned) {
          if (collections[contractAddress]) {
            collections[contractAddress].tokens += 1;
            collections[contractAddress].nfts.push({
              tokenAddress: contractAddress,
              tokenId: tokenId
            })
            setNftsFound((nftsFound) => nftsFound + 1)
          } else {
            collections[contractAddress] = {
              name: nfts[i].contractMetadata.name,
              tokens: 1,
              tokenType: nfts[i].contractMetadata.tokenType,
              image: nfts[i].metadata.image || nfts[i].metadata.image_url || nfts[i].metadata.image_data,
              contractAddress: contractAddress,
              nfts: [{
                tokenAddress: contractAddress,
                tokenId: tokenId
              }]
            }
            setNftsFound((nftsFound) => nftsFound + 1)
          }
        }

      }

      for (let i = 0; i < transfers.length; i++) {
        const contractAddress = transfers[i].rawContract.address.toLowerCase();
        const tokenId = transfers[i].tokenId;

        const nftOwned = await checkIfNftOnPulseChain(contractAddress, tokenId, address)
        if (nftOwned) {
          if (collections[contractAddress]) {
            collections[contractAddress].tokens += 1;
            collections[contractAddress].nfts.push({
              tokenAddress: contractAddress,
              tokenId: tokenId
            })
            setNftsFound((nftsFound) => nftsFound + 1)
          } else {
            let metadata;
            try {
              metadata = await axios(`${apiEndpoint}/metadata?contract=${contractAddress}&&tokenId=${web3.utils.hexToNumberString(tokenId)}`);
            } catch (error) {
              setRetrieving(false)
              setError("Internal Server Error. Please try again later.")
              return;
            }
            if (typeof metadata.data.metadata.metadata === "string") {
              metadata.data.metadata.metadata = JSON.parse(metadata.data.metadata.metadata);
            }
            collections[contractAddress] = {
              name: metadata.data.metadata.name ? metadata.data.metadata.name : metadata.data.metadata.contractMetadata.name,
              tokens: 1,
              tokenType: metadata.data.metadata.contract_type ? metadata.data.metadata.contract_type : metadata.data.metadata.contractMetadata.tokenType,
              image: metadata.data.metadata.metadata ? (metadata.data.metadata.metadata.image || metadata.data.metadata.metadata.image_url || metadata.data.metadata.metadata.image_data) : (metadata.data.metadata.image || metadata.data.metadata.image_data || metadata.data.metadata.image_url),
              contractAddress: contractAddress,
              nfts: [{
                tokenAddress: contractAddress,
                tokenId: tokenId
              }]
            }
            setNftsFound((nftsFound) => nftsFound + 1)
          }

        }

      }

      setCollectionsFound(Object.keys(collections).length)
      setAddresses(Object.keys(collections))
      setCollections(collections)
      setRetrieving(false)

    } else {
      setError("Invalid address.")
    }

  }

  const ethEnabled = async () => {
    if (window.ethereum) {
      try {
        const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
        addressRef.current.value = accounts[0];
        setError(null)
      } catch (error) {
        setError("Please connect your Metamask wallet.")
      }
    } else {
      setError("Metamask not detected.")
    }

  }

  const convert = (sourceUrl) => {
    const desiredGatewayPrefix = "https://ipfs.io";
    try {
      const convertedGatewayUrl = gatewayTools.convertToDesiredGateway(
        sourceUrl,
        desiredGatewayPrefix
      );
      return convertedGatewayUrl;
    } catch (error) {
      return sourceUrl
    }
  }

  const processImage = (nft) => {
    if (nft.image) {
      return convert(nft.image)
    }
    return notfound
  }

  const processTitle = (nft) => {
    if (nft.name) {
      return nft.name
    }
    return null;
  }

  const processDesc = (nft) => {
    if (nft.tokens) {
      return nft.tokens
    }
    return null;
  }

  const processType = (nft) => {
    if (nft.tokenType) {
      return nft.tokenType.replace("E", "P")
    }
    return null;
  }

  const searchCollection = () => {
    setErrorSearch(null)
    const collectionAddress = collectionRef.current.value;
    if (collectionAddress) {
      if (web3.utils.isAddress(collectionAddress)) {
        setSearchAddress(collectionAddress.toLowerCase())
      } else {
        setErrorSearch("Invalid address.");
      }

    }
  }

  const cancelCollection = () => {
    collectionRef.current.value = ""
    setSearchAddress(null)
  }

  return (
    <div className="App">

      <header className="App-header">

        <img src={logo} alt="Website Logo" className="logo" />
        <h3 style={{ marginTop: "0px" }}>NFT Search Tool</h3>
        <p>Find your PRC-721 NFT Copies from Ethereum!</p>
        {/* <DonateButton/> */}
      </header>

      <p id="rate-limit">Rate Limit: 5 searches per day.</p>
      {
        retrieving &&
        <div>
          <p style={{ marginBottom: "0px" }} className="rainbow rainbow_text_animated">Searching for NFT copies...</p>
          <p style={{ color: "white" }}>{nftsFound} NFTs found.</p>
        </div>
      }
      {collections !== null && addresses.length > 0 && !error && !retrieving && <h3 className="rainbow rainbow_text_animated">{collectionsFound} collections found!</h3>}
      {collections !== null && addresses.length === 0 && !error && !retrieving && <h3 className="rainbow rainbow_text_animated">No collections found.</h3>}
      {error && !retrieving && <p className="error error_text_animated">{error}</p>}

      <div className="input-container">
        <input ref={addressRef} type="text" placeholder="Wallet address" className="input-field" disabled />
        <button className="button" onClick={makeAPICall} disabled={retrieving}>View NFTs</button>
        <button className="button" id="get-address" onClick={ethEnabled}>Get address from wallet</button>
      </div>

      {errorSearch && <p style={{ marginTop: "0px", marginBottom: "5px", color: "red" }}>{errorSearch}</p>}
      {collections !== null && addresses.length > 0 && <div className="search-container">
        <input type="text" ref={collectionRef} className="input-field" placeholder="Search collection address..." disabled={searchAddress ? true : false} />
        {searchAddress ? <FaTimes className="search-icon" onClick={cancelCollection} /> : <FaSearch className="search-icon" onClick={searchCollection} />}
      </div>}


      <div className="nfts">
        {addresses && !searchAddress ? addresses.map((contractAddress, index) => {
          return (
            <div className="nft" key={index}>
              <p className="description">{processType(collections[contractAddress])}</p>
              <div className="image">
                <img src={processImage(collections[contractAddress])} alt={processTitle(collections[contractAddress])} />
              </div>
              <div className="content">
                <h3 className="title">{processTitle(collections[contractAddress])}</h3>
                <p className="description">Number of NFTs owned: {processDesc(collections[contractAddress])}</p>
              </div>
              <button className="nft-button" address={collections[contractAddress].contractAddress} name={processTitle(collections[contractAddress])} image={processImage(collections[contractAddress])} onClick={togglePopup}>More Info</button>
            </div>
          )
        }) : (searchAddress && addresses && collections[searchAddress] ? (
          <div className="nft">
            <p className="description">{processType(collections[searchAddress])}</p>
            <div className="image">
              <img src={processImage(collections[searchAddress])} alt={processTitle(collections[searchAddress])} />
            </div>
            <div className="content">
              <h3 className="title">{processTitle(collections[searchAddress])}</h3>
              <p className="description">Number of NFTs owned: {processDesc(collections[searchAddress])}</p>
            </div>
            <button className="nft-button" address={collections[searchAddress].contractAddress} name={processTitle(collections[searchAddress])} image={processImage(collections[searchAddress])} onClick={togglePopup}>More Info</button>
          </div>) : (searchAddress && addresses && !collections[searchAddress] && <p id="not-found">No collection matches this address.</p>))}
      </div>

      {isOpen && (
        <div className="popup-container">
          <div className="popup-content" ref={popupRef}>
            <div className="close-button" onClick={closePopup}>
              <span className="close-symbol">&times;</span>
            </div>
            <div className="image" style={{ margin: "0 auto" }}>
              <img src={activeCollection && activeCollection.image} alt={activeCollection && activeCollection.name} />
            </div>
            <div className="content">
              <h3 className="title">{activeCollection && activeCollection.name}</h3>
              <p className="description">Number of NFTs owned: {activeCollection && collections[activeCollection.contractAddress].nfts.length}</p>
            </div>
            <div className="nfts" style={{ height: "200px", overflow: "scroll" }}>
              {activeCollection && collections[activeCollection.contractAddress].nfts.map((nft, index) => {
                const tokenId = web3.utils.hexToNumberString(nft.tokenId);
                return (
                  <div className="nft" key={index}>
                    <div className="content">
                      <h3 className="title" style={{ marginTop: "0px" }}>Token ID: {tokenId.length > 10 ? `${tokenId.substring(0, 10)}...` : tokenId}</h3>
                    </div>
                    <button style={{ marginBottom: "10px" }} className="nft-button" onClick={() => window.open(`https://etherscan.io/nft/${activeCollection.contractAddress}/${tokenId}`)}>View on Etherscan</button>
                    <button className="nft-button" onClick={() => window.open(`https://scan.pulsechain.com/token/${activeCollection.contractAddress}/instance/${tokenId}`)}>View on PulseScan</button>
                  </div>
                )
              })}
            </div>

          </div>
        </div>
      )}
    </div>
  );
}


export default App;
